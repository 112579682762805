import React, { useRef, useState } from "react";
import "./Header.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../services/auth0.service";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import mainlogo from "../../assets/img/Logo.svg";
import menu from "../../assets/img/menu.svg";
import health from "../../assets/img/health.svg";
import nurse from "../../assets/img/nurse.svg";
import mood from "../../assets/img/mood.svg";
import symptom from "../../assets/img/symptom.svg";
import remotePhysio from "../../assets/img/remotePhysio.svg";
import remoteGp from "../../assets/img/remoteGp.svg";
import close from "../../assets/img/close.svg";
import Button from "../Button/Button";
import BorderButton from "../BorderButton/BorderButton";
import { FaAngleRight, FaSearch } from "react-icons/fa";
import { Form, InputGroup } from "react-bootstrap";
import { DataStore } from "../../store";
import { useEffect } from "react";
import { userAxios } from "../../utils/config";
import { axiosInstance } from "../../utils/config/axiosInstance";
import axios from "axios";
import Loader from "../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { handleLogout, openInNewTab } from "../../utils/common";

const Header = () => {
  const [show, setShow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const userId = sessionStorage.getItem("authUserId");
  const userToken = sessionStorage.getItem("accessToken");
  const email = sessionStorage.getItem("email");
  const dispatch = useDispatch();
  const selectorData = useSelector((store) => store.user);
  const { modalOpenState, modalOpenDispatch, loadingDispatch, loadingState } =
    DataStore();
  // console.log("loadingState", loadingState);
  // const [helpModal, setHelpModal] = useState(false)
  const headerRef = useRef(null);

  
  const handleLogoutButton = () => {
    handleLogout(email,false);
  };
  useEffect(() => {
    window.FreshworksWidget("close");
  }, [location]);
  const onSelect = (e) => {
    // console.log("OnSelect", e);
    // console.log(this.state.navExpanded);
    // this.setState({navExpanded: false});
  };
  const closeMenu = () => {
    setExpanded(false);
  };
  useEffect(() => {
    setData(selectorData?.userProfile)
  })
  const getData = async () => {
    try {
      const response = await userAxios.get(`getuser/${userId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      // console.log("response dashboard ==>", response);

      // setData(response.data);
    } catch (error) {
      // console.log("eeror in dashboard", error);
      // console.log("eeror in dashboard", error);
    }
  };

    const birthdateFromRedux = data?.userInfo?.user_metadata?.birthdate;
  let birthdate = "";
  if (birthdateFromRedux) {
      const parts = birthdateFromRedux.split('/');
      const formattedBirthdate = `${parts[2]}-${parts[1].padStart(2, '0')}-${parts[0].padStart(2, '0')}`;
  
      // Update the birthdate in the Redux store or data object
      birthdate = formattedBirthdate;
  }
  
  const today = new Date();
  const formattedToday = today.toISOString().slice(0, 10);

  const remoteRedirect = async () => {
    // http://localhost:3264/api/v1/phio/getaccess
    // http://54.201.160.69:3264/api/v1/phio/getaccess
    setLoading(true);
    try {
      const responseIframe = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL_DEVELOPMENT}/api/v1/phio/getaccess`,
        {
          email: data?.userInfo?.email,
        }
      );
      const authorizationCode = responseIframe?.data?.data?.data?.authorization_code;
      if (authorizationCode) {
        openInNewTab(`${process.env.REACT_APP_PHIO_URL}?code=${authorizationCode}`);
      }
      setLoading(false);
    } catch (error) {
      console.log("error-->", error);
      toast.error(error.response.data.message, { autoClose: 3000 });
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (userId && userToken) getData();
  }, []);

  function openWidget() {
    setExpanded(false)
    window.FreshworksWidget('open');
    }

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (headerRef.current && !headerRef.current.contains(event.target)) {
          setExpanded(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  

  return (
    // expand="xxl"
    <>
      {/* { loading ? ( 
        <>
          <Loader />
        </>
      ) :  */}
       {/* (  */}
        <Navbar expand="xxl" expanded={expanded} className="site-header">
          <Container ref={headerRef}>
            <Navbar.Brand>
              <Link to="/">
                <img src={mainlogo} />
              </Link>
            </Navbar.Brand>
            {userId ? (
              <Navbar.Toggle
                onClick={() => {
                  setShow(false);
                }}
                className="justify-content-end"
                aria-controls="offcanvasNavbar-expand-xxl"
              >
                <Navbar.Text onClick={() => setExpanded(true)}>
                  <img src={menu} />
                  <span>Menu</span>
                </Navbar.Text>
              </Navbar.Toggle>
            ) : (
              <></>
            )}

            <Navbar.Offcanvas
              // id="offcanvasNavbar-expand-xxl"
              // aria-labelledby="offcanvasNavbar-expand-xxl"
              placement="end"
              className="show"
              style={{ display: show && "none" }}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title
                  onClick={() => {
                    closeMenu();
                  }}
                  id="offcanvasNavbar-expand-xxl "
                >
                  <Nav.Link className="close-menu">
                    <span className="menu-label">Close</span>
                    <img src={close} alt="close-icon" />
                  </Nav.Link>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="site-menu">
                {/* When user Signed in */}
                {userId ? (
                  <>
                    <Nav
                      className="right-sidebar-default"
                      onClick={() => {
                        onSelect();
                      }}
                    >
                      {/* <Nav.Link
                        onClick={() => {
                          closeMenu();
                        }}
                        className="menu-item"
                        as={Link}
                        to="/health-assesment"
                      >
                        <span className="icon">
                          <img src={health} />
                        </span>
                        <span className="menu-label">Health assessment</span>
                      </Nav.Link> */}
                      
                      <Nav.Link
                        onClick={() => {
                          closeMenu();
                        }}
                        className="menu-item"
                        as={Link}
                        to="/request-champion"
                      >
                        <span className="icon">
                          <img src={nurse} />
                        </span>

                        <span className="menu-label">Nurse champion</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() => {
                          closeMenu();
                        }}
                        className="menu-item"
                        as={Link}
                        to="/mood-journal"
                      >
                        <span className="icon">
                          <img src={mood} />
                        </span>
                        <span className="menu-label">Mood journal</span>
                      </Nav.Link>
                      {/* <Nav.Link
                        onClick={() => {
                          closeMenu();
                        }}
                        className="menu-item"
                        as={Link}
                        to="/symptom-checker"
                      > */}
                      <a
                        href={"https://111.nhs.uk/"}
                        className="menu-item"
                        style={{ color: "#212529", textDecoration: "none" }}
                        target={"_blank"}
                      >
                        <span className="icon">
                          <img src={symptom} />
                        </span>
                        <span className="menu-label">Symptom checker</span>
                        </a>
                      {/* </Nav.Link> */}
                      <Nav.Link
                        onClick={() => {
                          closeMenu();
                          remoteRedirect();
                        }}
                        className="menu-item"
                        as={Link}
                      >
                        <span className="icon">
                          <img src={remotePhysio} />
                        </span>
                        <span className="menu-label">Remote physio</span>
                      </Nav.Link>

                      <a
                        href={process.env.REACT_APP_REMOTE_GP_URL}
                        className="menu-item"
                        style={{ color: "#212529", textDecoration: "none" }}
                        target={"_blank"}
                      >
                        {/* <Nav.Link  className="menu-item" as={Link} to="/"> */}
                        <span className="icon">
                          <img src={remoteGp} />
                        </span>
                        <span className="menu-label">Remote GP</span>
                        {/* </Nav.Link> */}
                      </a>
                    </Nav>

                    <Nav className="right-sidebar-default-bottom">
                      <Nav.Link
                        onClick={() => {
                          closeMenu();
                        }}
                        as={Link}
                        to="/account"
                      >
                        <span className="menu-label">Your account</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() => openWidget()}
                      >
                        <span className="menu-label">Help</span>
                      </Nav.Link>
                      <Nav.Link onClick={() => (handleLogoutButton(), closeMenu())}>
                        <span className="menu-label">Log out</span>
                      </Nav.Link>
                    </Nav>
                  </>
                ) : (
                  <Nav className="right-sidebar-default">
                    {/* <Nav.Link as={Link} onClick={()=>(closeMenu(),console.log('expanded',expanded))} to="/">
                
                <Button name="Sign in" />
              </Nav.Link> */}
                    <Nav.Link
                      as={Link}
                      onClick={() => {
                        closeMenu();
                      }}
                      to="/"
                    >
                      <Button name="Sign in" />
                    </Nav.Link>
                    <div
                      onClick={() => {
                        closeMenu();
                      }}
                    >
                      <Nav.Link
                        as={Link}
                        onClick={() => {
                          closeMenu();
                        }}
                        to="register"
                      >
                        <BorderButton name="Create an account" />
                      </Nav.Link>
                    </div>
                  </Nav>
                  // <Nav className="right-sidebar-default-bottom">
                  //   <Nav.Link as={Link} onClick={()=>{closeMenu()}} to="/">
                  //     <span className="menu-label">Login</span>
                  //   </Nav.Link>
                  //   <Nav.Link as={Link} onClick={()=>{closeMenu()}} to="/register">
                  //     <span className="menu-label">Register</span>
                  //   </Nav.Link>
                  // </Nav>
                )}
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      {/* //  )}  */}
    </>
  );
};

export default Header;
