import { toast } from "react-toastify";
import { auth } from "../services/auth0.service";
// import socket from "./config/socket";

export const handleLogout = (email, data) => {
    sessionStorage.clear();
    auth.logout({
      returnTo: `${process.env.REACT_APP_LOGOUT_URL}`, 
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      federated: true
    });
    if(data) {
      toast.error("Dual Login Detect. You are logged out.");
    }
  //  return socket.emit('isLoggedIn', {email, isLoggedIn: data});
  };

  export function openInNewTab(url) {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.rel = 'noopener noreferrer'; 
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }